import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import RQLCompositeNumberFilter from '~/app/backoffice/components/Dashboard/Filters/RQLCompositeNumberFilter';
import RQLCompositeTextFilter from '~/app/backoffice/components/Dashboard/Filters/RQLCompositeTextFilter';
import RQLDateRangeFilter from '~/app/backoffice/components/Dashboard/Filters/RQLDateRangeFilter';
import RQLDropdownFilter from '~/app/backoffice/components/Dashboard/Filters/RQLDropdownFilter';
import EventFilter from '~/app/backoffice/components/Dashboard/LazyFilters/EventFilter';
import LazyDropdownFilter from '~/app/backoffice/components/Dashboard/LazyFilters/LazyDropdownFilter';
import {
  SaveSegmentButton,
  SaveSegmentModal,
} from '~/app/backoffice/components/Dashboard/Segments';
import { useCurrentSegment } from '~/app/backoffice/hooks';
import { rqlHasValue, useCurrentExpressionWithoutPage } from '~/app/backoffice/utils';
import actions from '~/app/entities/actions';
import Autocomplete from '~/app/inputs/components/Autocomplete';
import { useRQLFiltersContext } from '~/app/rosters/RQLFiltersContext';
import colors from '~/services/colors';
import { titleCase } from '~/services/utils';
import { Button } from '~/app/shared';
import { DynamicFilter, RQLFilter } from '~/app/shared/components/types';
import {
  filter,
  includes,
  isEmpty,
  map,
  concat,
  keys,
  head,
  isNil,
  size,
  toInteger,
  reduce,
  join,
  get,
  isNaN,
} from 'lodash-es';
import { Link } from '~/common/components/Links';
import { Box, ClickAwayListener, Grid2 as Grid, IconButton, Typography } from '@mui/material';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { AddIcon } from '~/vendor/mui-icons';
import { useEditSegment } from '~/features/segments/api/mutations';
import { toast } from '~/app/notifications/components/NotificationCenter';

interface MenuOption {
  icon: string;
  title: string;
}

interface FilterComponentProps {
  filterComponentMapping: { [filterName: string]: DynamicFilter };
  filter: RQLFilter;
  onChange: (filter: RQLFilter) => void;
  handleRemoveFilter: () => void;
  index: number;
  inputWidth?: string;
}

interface FilterBarProps {
  contentType: string;
  filters: RQLFilter[];
  dynamicFilters: DynamicFilter[];
  addFilter: (filter: RQLFilter) => void;
  removeFilter: (index: number) => void;
  updateFilter: (index: number, filter: RQLFilter) => void;
  updateFilters: (filters: RQLFilter[]) => void;
  inputWidth?: string;
  enableSegments?: boolean;
  cacheKey?: string;
}

const FilterComponent = ({
  filterComponentMapping,
  filter,
  onChange,
  handleRemoveFilter,
  index,
  inputWidth = '352px',
}: FilterComponentProps) => {
  const filterName = head(keys(filter));
  if (isNil(filterName)) return null;
  const item = filterComponentMapping[filterName];
  const key = `filterbar-component-${filterName}-${index}`;
  const label = isNil(item.input.custom_label) ? titleCase(item.filter) : item.input.custom_label;
  const filterProps = { key, label, filter, onChange, handleRemoveFilter, inputWidth };

  if (item.input.type === 'select')
    return <RQLDropdownFilter options={item.input.options} {...filterProps} />;

  if (item.input.type === 'single_select')
    return <RQLDropdownFilter options={item.input.options} {...filterProps} single_select />;

  if (item.input.type === 'model_select') {
    return (
      <LazyDropdownFilter
        actionFunc={actions[item.input.action_name || ''].retrieveList}
        getFetchExtraParams={() => ({
          ...item.input.extra_params,
        })}
        isRQL
        {...filterProps}
      />
    );
  }
  if (item.input.type === 'event') return <EventFilter isRQL {...filterProps} />;
  if (item.input.type === 'period') return <RQLDateRangeFilter {...filterProps} />;
  if (item.input.type === 'number') return <RQLCompositeNumberFilter {...filterProps} />;
  return (
    <RQLCompositeTextFilter
      {...filterProps}
      defaultOperator={get(item, 'input.default_operator', 'contains')}
    />
  );
};

const FilterBarItemWrapper = styled.div`
  padding-top: 4px;
`;

const strToInt = (s: string): number => {
  return toInteger(
    join(
      filter(s, (char) => !isNaN(Number.parseInt(char, 10))),
      ''
    )
  );
};

const DynamicFilterBar = ({
  contentType,
  filters,
  dynamicFilters,
  addFilter,
  removeFilter,
  updateFilter,
  updateFilters,
  inputWidth = '352px',
  enableSegments = true,
  cacheKey = '',
}: FilterBarProps) => {
  const { isDefaultFiltersUpdated, setDefaultFiltersUpdated } = useRQLFiltersContext();
  const [addedDefaultFilters, setAddedDefaultFilters] = useState(isDefaultFiltersUpdated(cacheKey)); // Ensure to add the default filters only once.
  const [isAddingNewFilter, setIsAddingNewFilter] = useState(false);

  const currentSegment = useCurrentSegment();
  const editSegmentMutation = useEditSegment(currentSegment.public_id);
  const isSegment = !isEmpty(currentSegment);
  const [showSaveNewSegmentModal, setShowSaveNewSegmentModal] = useState(false);
  const filterComponentMapping: { [filterName: string]: DynamicFilter } = reduce(
    dynamicFilters,
    (acc, item) => {
      acc[item.filter] = item;
      return acc;
    },
    {}
  );

  const updateSegment = async (values) => {
    const response = await editSegmentMutation.mutateAsync(values);
    if (response) toast.success('Segment successfully saved.');
  };

  const filterMenuOptionsMapping: { [filterName: string]: MenuOption } = reduce(
    dynamicFilters,
    (acc: { [key: string]: MenuOption }, item: DynamicFilter) => {
      acc[item.filter] = {
        icon: item.input.icon,
        title: isNil(item.input.custom_label) ? titleCase(item.filter) : item.input.custom_label,
      };
      return acc;
    },
    {}
  );

  const defaultFilterNames: string[] = map(
    filter(dynamicFilters, (item: DynamicFilter) => item.input.default),
    (item: DynamicFilter) => item.filter
  );

  const allFilterNames: string[] = filter(
    map(dynamicFilters, (item) => item.filter),
    (item) => !includes(['starts_after', 'starts_before'], item)
  );

  const canAddNewFilters = !isEmpty(allFilterNames);

  const expression: string = useCurrentExpressionWithoutPage();
  const helpFilterLink =
    'https://help.plusplus.app/en/articles/6473477-how-dynamic-filters-work-on-dashboards';

  const loadDefaultFilters = useCallback(() => {
    if (isNil(filters) || addedDefaultFilters || !isNil(currentSegment?.public_id)) return;
    // Add default filters only if the filter does not already exist in the filter list.
    const filterNames = map(filters, (filterObj) => head(keys(filterObj)));
    const filtersToAdd = filter(
      defaultFilterNames,
      (filterName: string) => !includes(filterNames, filterName)
    );
    if (size(filtersToAdd) > 0) {
      updateFilters(
        concat(
          filters,
          map(filtersToAdd, (filterName: string) => ({ [filterName]: null }))
        )
      );
    }
    setAddedDefaultFilters(true);
    setDefaultFiltersUpdated(cacheKey);
  }, [
    filters,
    addedDefaultFilters,
    currentSegment,
    defaultFilterNames,
    updateFilters,
    setDefaultFiltersUpdated,
    cacheKey,
  ]);

  useEffect(() => {
    loadDefaultFilters();
  }, [loadDefaultFilters]);

  const shouldRenderSaveSegmentButton =
    enableSegments && size(filter(filters, (filterObj) => rqlHasValue(filterObj))) > 0;

  const getOptions = () => {
    return map(allFilterNames, (option) => ({
      label: filterMenuOptionsMapping[option].title,
      value: option,
    }));
  };

  const handleHideAddFilterButton = () => {
    setIsAddingNewFilter(false);
  };

  const handleAddNewFilter = (newFilter: string) => {
    setIsAddingNewFilter(false);
    addFilter({ [newFilter]: null });
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        border: `1px solid ${colors.neutral200}`,
        borderRadius: 1,
        p: 2,
        mb: 2,
        display: 'flex',
        gap: '12px',
        justifyContent: 'space-between',
      }}
    >
      {filterComponentMapping && (
        <Grid container rowSpacing={2} columnSpacing="12px">
          {map(filters, (filter, index, { length }) => {
            const filterName = head(keys(filter));
            const isNotLastFilter = index < length - 1;

            return (
              <>
                <Grid key={`filter-${filterName}`} sx={{ display: 'flex', alignItems: 'center' }}>
                  <FilterComponent
                    filterComponentMapping={filterComponentMapping}
                    filter={filter}
                    onChange={(filter: RQLFilter) => updateFilter(index, filter)}
                    handleRemoveFilter={() => removeFilter(index)}
                    index={index}
                    inputWidth={inputWidth}
                  />
                </Grid>

                {(isNotLastFilter || isAddingNewFilter) && (
                  <Grid display="flex" alignItems="center">
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        color: colors.neutral500,
                      }}
                    >
                      and
                    </Typography>
                  </Grid>
                )}
              </>
            );
          })}

          {canAddNewFilters && (
            <ClickAwayListener onClickAway={handleHideAddFilterButton}>
              <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                <>
                  {!isAddingNewFilter ? (
                    <Button
                      variant="text"
                      onClick={() => {
                        setIsAddingNewFilter(true);
                      }}
                      aria-label="Add Filter"
                      startIcon={<AddIcon />}
                    >
                      Add Filter
                    </Button>
                  ) : (
                    <Autocomplete
                      label="Choose a filter"
                      disabled={false}
                      options={getOptions()}
                      onChange={handleAddNewFilter}
                      inputMinWidth={`${strToInt(inputWidth) - 27}px`}
                    />
                  )}
                </>
              </Grid>
            </ClickAwayListener>
          )}

          {isSegment && shouldRenderSaveSegmentButton && (
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <FilterBarItemWrapper>
                <SaveSegmentButton onClick={() => updateSegment({ expression })} />
              </FilterBarItemWrapper>
            </Grid>
          )}

          {shouldRenderSaveSegmentButton && (
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <FilterBarItemWrapper>
                <SaveSegmentButton
                  label={isSegment ? 'Save As' : 'Save'}
                  onClick={() => setShowSaveNewSegmentModal(true)}
                />
                {showSaveNewSegmentModal && (
                  <SaveSegmentModal
                    contentType={contentType}
                    handleClose={() => setShowSaveNewSegmentModal(false)}
                  />
                )}
              </FilterBarItemWrapper>
            </Grid>
          )}
        </Grid>
      )}
      <Box>
        <IconButton size="small" component={Link} href={helpFilterLink} target="_blank">
          <ContactSupportOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DynamicFilterBar;
